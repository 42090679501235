// Global theme for PRIME PAGINATOR
// WE ARE NOT SUPPOSED to add custom styles on per page or per component on this APP.
// You shouldn't really be touching this unless styling globally.

@import '../variables';

$paginator-borders: 1px solid #E0E4E5;

body .ui-paginator {
  height: 35px;
  border: none;
}

body .ui-paginator .ui-paginator-page,
body .ui-paginator .ui-paginator-pages,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last,
body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-current {
  margin-left: 0;
  margin-right: 0;
}

body .ui-paginator .ui-paginator-element {
  box-shadow: none;
  border-left: $paginator-borders;
  border-top: $paginator-borders;
  border-right: $paginator-borders;
  border-bottom: $paginator-borders;
  margin: 0;
}

body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last {
  color: $primary;
  padding: 7px 14px;
  font-weight: 500;
  height: 100%;
}

body .ui-paginator .ui-paginator-next {
  border-radius: 0;
}

body .ui-paginator .ui-paginator-prev {
  border-radius: 0;
}

body .ui-paginator .ui-paginator-first {
  border-radius: 5px 0 0 5px;
  width: 50px;
  border-right: none;
}

body .ui-paginator .ui-paginator-last {
  border-radius: 0 5px 0 0;
  width: 50px;
  border-left: none;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active,
body .ui-paginator .ui-paginator-pages .ui-paginator-page:hover.ui-state-active {
  color: white;
  background-color: $primary;
  border-color: $primary;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  color: $primary;
  min-width: 1.5rem;
  padding: 7px 14px;
  height: 100%;
  border-radius: 0;
  margin: 0;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  border-top: $paginator-borders;
  border-right: $paginator-borders;
  border-bottom: $paginator-borders;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page:first-child {
  border-left: none;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page:last-child {
  border-right: none;
}

body .ui-paginator .ui-paginator-pages {
  height: 100%;
}
