// Main Bootstrap Override theme
// Styles here for the project

// Set the primary color
@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "primeng/paginator";
@import "primeng/prime.scss";
@import "primeng/dropdown";
@import "primeng/tables";
@import "primeng/checkbox";
@import "primeng/fileupload";
@import "primeng/multiselect";
@import "primeng/radio";
@import "primeng/calendar";
@import "primeng/overlaypanel";
@import "primeng/editor";
@import "primeng/spinner";
@import "primeng/input";
@import "primeng/inputmask";
@import "primeng/rating";

@import "bootstrap/modal";
@import "bootstrap/alert";
@import "bootstrap/accordion";

@import "thirdparty/draggable-list";

@import "controls";
@import "tables";

html,
body {
  height: 100%;
}

body {
  background-color: #F6F6F6;
}

// Global Form Labels
label {
  font-size: 0.96rem;
  text-transform: none;
  font-weight: 600;
  color: #3A3A3A;
  margin-bottom: 0.2rem;
}

// Style the light button to have green
.btn-light {
  color: $primary;
}

.form-control:disabled {
  border-color: #e4e4e4;
}

.app-accordion.panel-primary {
  border-color: $primary;
}

.app-accordion.panel-primary .panel-heading {
  background: $primary;
  color: $light;
}

.cursor-pointer {
  cursor: pointer;
}

.text-transform-none {
  text-transform: none !important;
}

// TODO: Find a way to for us to actually use bootstrap's validation classes
.form-control.ng-dirty.ng-invalid,
.form-control.ng-touched.ng-invalid {
  border-color: $danger;
  border-width: 2px;
}

.form-control[readonly] {
  background-color: $input-disabled-bg;
  border-color: $input-disabled-bg;
}

.input-group.input-group-lg .input-group-prepend,
.input-group.input-group-lg .input-group-append {
  height: $input-height-lg;
}

a[role="button"]:not([href]):not([tabindex]),
a[role="button"]:not([href]):not([tabindex]):hover,
a[role="button"]:not([href]):not([tabindex]):focus {
  cursor: pointer;
  color: $primary;
}

a[role="button"]:not([href]):not([tabindex]):active {
  cursor: pointer;
  color: white;
}

.pac-container {
  z-index: 1050;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-95 {
  width: 95%;
}

// This is to support the 600 weight of our default "Source Sans" font
.font-weight-semibold {
  font-weight: 600;
}

//noinspection CssOverwrittenProperties
.break-text {
  word-break: break-all; // IE11 fix - 'break-word' isn't supported
  word-break: break-word;
}

.text-disabled {
  opacity: 0.4;
}

/* This will remove the blue border-left of <blockquote type="cite">
  * Will remove also the padding left to match to other browsers (IE, Edge, and Chrome)
  * This is a Firefox specific / Bug-6388
  * https://htmlcss.fandom.com/wiki/Blockquote
  */
blockquote[type=cite] {
  border-left-width: 0;
  padding-left: 0;
}

textarea.no-resize {
  resize: none;
}

.container.wide-table {
  max-width: 1550px;
}

// NOTE: You cannot make adjustments to this style.
// Please ask for assistance before changing this.
.super-section-header {
  background-color: #525A62;
  color: white;
  font-size: 24px;
  font-weight: 700;
  padding: 7px 24px;
  display: inline-block;
  margin-left: -20px;
}

.super-section-header.no-offset {
  margin-left: 0;
}

// Global Styling for under-nav
.under-nav {
  display: flex;
  background-color:#5E8C39;
  height: 41px;

  // This is to override the bottom border from the shell navigation.
  // Do not remove unless you know what you're doing.
  margin-top: -1px;
}

.under-nav-items {
  display: flex;
  align-items: center;
}

.under-nav .under-nav-items .case-link {
  padding: 5px 12px;
  text-transform: uppercase;
  border-radius: 8px;
}

.under-nav .under-nav-items .case-link.active,
.under-nav .under-nav-items .case-link:hover {
  background-color: #315811;
  text-decoration: none;
}

// Used for badges that display service names
.badge.badge-service {
  background-color: white;
  border: 1px solid $primary;
  color: $primary;
  font-size: 13px;
  padding: 5px 16px;
}

.badge.badge-secondary.badge-on-hold {
  background-color: #5A607C;
}

.default-action-blocker {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background-color: white;
  opacity: 0.5;
}
