.ui-table.case-table .ui-table-thead > tr > th {
  border-width: 0;
  font-size: 13px;
}

.ui-table.case-table .ui-table-thead > tr:last-of-type > th {
  border-bottom: 2px solid #DEDEDE;
}

.ui-table.case-table .ui-table-tbody > tr:nth-child(even),
.ui-table.case-table .ui-table-tbody > tr {
  background-color: transparent;
}

.ui-table.case-table .ui-table-tbody > tr > td {
  border-width: 0;
  color: #4A4A4A;
  font-size: 13px;
}

.ui-table.case-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #FFFFFF;
}

.ui-table.case-table .ui-sortable-column.ui-state-highlight {
  color: #4A4A4A;
  background-color: transparent;
}

.ui-table.case-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: inherit;
}

.ui-table.case-table .ui-table-thead > tr > th {
  background: transparent;
}

.ui-table.case-table.super-tight .ui-table-thead > tr > th,
.ui-table.case-table.super-tight .ui-table-tbody > tr > td {
  font-size: 12px;
  padding: 7px 3px;
}

.ui-table.case-table.super-tight .ui-table-tbody > tr > td {
  border-bottom: 1px solid #dedede;
}
