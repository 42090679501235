@import '../../variables';

/** PrimeNG table styling for styleClass `files-table` **/

body .files-table.ui-table .ui-table-tbody {
  border: 1px solid #EEEEEE;
}

body .files-table.ui-table .ui-table-tbody tr {
  background-color: white;
}

body .files-table.ui-table .ui-table-thead tr th {
  padding: 10px 16px;
  background-color: #EEEEEE;
  border: none;
}

body .files-table.ui-table .ui-table-thead tr th.ui-sortable-column .ui-sortable-column-icon {
  font-size: 14px;
}

body .files-table.ui-table .ui-table-thead tr th.ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: $primary;
}

body .files-table.ui-table .ui-table-tbody tr td {
  padding: 16px;
  border: none;
  border-bottom: 1px solid #EEEEEE;
}

body .files-table.ui-table tr:focus {
  outline: none;
}

body .files-table.ui-table tr.ui-state-highlight {
  background-color: transparent;
  color: $body-color;
}

body .files-table.ui-table .ui-table-thead tr th.ui-state-highlight {
  color: $primary;
}

body .files-table.ui-table .ui-table-thead tr th:focus {
  outline: none;
}

body .files-table.ui-table .ui-table-thead tr th:first-of-type {
  border-top-left-radius: 3px;
}

body .files-table.ui-table .ui-table-thead tr th:last-of-type {
  border-top-right-radius: 3px;
}

body .files-table.ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight  {
  background-color: transparent;
  color: #4A4A4A;
}