.ui-table.billing-table .ui-table-thead > tr > th {
  border-width: 1px 0 1px 0;
  font-size: 15px;
  background-color: #E2E4E7;
  height: 50px;
  border-color: #E2E4E7;
}

.ui-table.billing-table .ui-table-thead > tr > th:first-of-type {
  border-width: 1px 0 1px 1px;
}

.ui-table.billing-table .ui-table-thead > tr > th:last-of-type {
  border-width: 1px 1px 1px 0;
}

.ui-table.billing-table .ui-table-tbody > tr:nth-child(even),
.ui-table.billing-table .ui-table-tbody > tr {
  background-color: transparent;
  height: 67px;
}

.ui-table.billing-table .ui-table-tbody > tr > td:first-of-type {
  border-width: 0 0 2px 1px;
  color: #4A4A4A;
}

.ui-table.billing-table .ui-table-tbody > tr > td:last-of-type {
  border-width: 0 1px 2px 0;
  color: #4A4A4A;
}

.ui-table.billing-table .ui-table-tbody > tr > td {
  border-width: 0 0 2px 0;
  border-color: #E2E4E7;
  color: #4A4A4A;
}

.ui-table.billing-table .ui-table-tbody > tr:last-of-type > td:first-of-type {
  border-width: 0 0 1px 1px;
  color: #4A4A4A;
}

.ui-table.billing-table .ui-table-tbody > tr:last-of-type > td:last-of-type {
  border-width: 0 1px 1px 0;
  color: #4A4A4A;
}

.ui-table.billing-table .ui-table-tbody > tr:last-of-type > td {
  border-width: 0 0 1px 0;
  color: #4A4A4A;
}

.ui-table.billing-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #e3e3e3;
}

.ui-table.billing-table .ui-sortable-column.ui-state-highlight {
  color: #4A4A4A;
}

.ui-table.billing-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: inherit;
}
