// Global theme for PRIME RADIO BUTTONS
// WE ARE NOT SUPPOSED to add custom styles on per page or per component on this APP.
// You shouldn't be changing this unless you know what you are doing.
@import "../variables.scss";

body .ui-radiobutton-label {
  text-transform: none;
  font-size: 1em;
}

p-radioButton.ng-touched.ng-invalid .ui-radiobutton .ui-radiobutton-box,
p-radioButton.ng-touched.ng-invalid .ui-radiobutton-label,
p-radioButton.ng-dirty.ng-invalid .ui-radiobutton-label {
  color: $danger;
  border-color: $danger;
  border-width: 2px;
  font-weight: bold;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active,
body .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-focus,
body .ui-radiobutton .ui-radiobutton-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: $primary;
  background-color: $primary;
}
