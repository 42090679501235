.expert-other-services .ui-overlaypanel {
  top: 20px !important;
  left: auto !important;
  right: -15px !important;
  margin-bottom: 10px;
}

.case-overlay.ui-overlaypanel:before,
.case-overlay.ui-overlaypanel:after {
  display: none;
}

.case-overlay.ui-overlaypanel {
  border: 1px solid #ACCA89;
  box-shadow: 0 0 7px 0 rgba(191,191,191,0.5);
  border-radius: 5px;
}

.recipient-overlay.ui-overlaypanel:before,
.recipient-overlay.ui-overlaypanel:after {
  display: none;
}

.recipient-overlay.ui-overlaypanel {
  border: 1px solid #ACCA89;
  box-shadow: 0 0 7px 0 rgba(191,191,191,0.5);
  border-radius: 5px;
  padding: 5px;
  width: 580px;
  top: 105px !important;
  left: auto !important;
  right: -18px;
}

.advanced-datepicker-calendar-overlay.ui-overlaypanel .ui-overlaypanel-content {
  padding: 0;
}

.translation-note.ui-overlaypanel {
  border-radius: 7px;
  top: 26px !important;
  left: auto !important;
  right: -44px !important;
  margin-bottom: 10px;
}

.translation-note.ui-overlaypanel .ui-overlaypanel-content {
  padding: 10px 18px 15px;
}

.translation-note.ui-overlaypanel:before,
.translation-note.ui-overlaypanel:after {
  right: 3.85em
}

.tooltip-overlay.ui-overlaypanel {
  width: 280px;
}

.tooltip-overlay.ui-overlaypanel:before,
.tooltip-overlay.ui-overlaypanel:after {
  content: none;
}

.tooltip-overlay.ui-overlaypanel .ui-overlaypanel-content {
  padding: 0;
}
