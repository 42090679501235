@import "./variables";
$quote-table-border-color: #DEDEDE;
$tracker-table-border-color: #E1E1E1;

.table.mission-control {
  border-collapse: separate;
  border-spacing: 0 2px;
}

.table.mission-control th {
  border-width: 0;
  font-weight: normal;
  font-size: 15px;
}

.table.mission-control tr td {
  border-width: 0;
  vertical-align: middle;
}

.table.mission-control tr.data td {
  background-color: #EEEEEE;
}

.table.mission-control tr.data td:first-of-type {
  border-left: 6px solid #7ED321;
  font-weight: 600;
}

.table.mission-control tr.data td:last-of-type {
  border-right: 6px solid #C6C6C6;
}

.table.mission-control tr.summary td {
  padding: 2px 0.75rem;
}

.table.quote-table {
  border-collapse: separate;
  border-spacing: 0 2px;
}

.table.quote-table th {
  font-weight: bold;
  border-width: 0;
}

.table.quote-table tr td {
  vertical-align: middle;
  border-color: $quote-table-border-color;
  border-style: solid;
  border-width: 1px 0;
  padding: 17px 12px;
}

.table.quote-table tr td:first-of-type {
  border-left: 1px solid $quote-table-border-color;
  border-radius: 5px 0 0 5px;
}

.table.quote-table tr td:last-of-type {
  border-right: 1px solid $quote-table-border-color;
  border-radius: 0 5px 5px 0;
}

.table.quote-table tr.summary td {
  background-color: #F6F6F6;
}

.table.quote-table tr.summary td:first-of-type {
  border-right: 1px solid #F6F6F6; // This is to resolve the white border on IE11
}

.table.quote-table tr.total td {
  background-color: $primary;
  color: #FFFFFF;
  border-right: 1px solid $primary; // This is to resolve the white border on IE11
}

.table.quote-table tr.success td {
  border: 1px solid $primary;
  background-color: $primary;
  color: #FFFFFF;
}

// ---------------------------------------------------------------------------------------------------------------
// START: Client Tracker Table
// ---------------------------------------------------------------------------------------------------------------
// If you need to use the client tracker style, you must wrap them under a container .client-tracker
// The client tracker styles encompasses any totals/subtotal item rendering
// ---------------------------------------------------------------------------------------------------------------

.client-tracker .table.tracker-quote-tbl {
  border-collapse: separate;
  border-spacing: 0 2px;
  margin-bottom: 0;
  font-size: 15px;
}

.client-tracker .table.tracker-quote-tbl th {
  font-weight: bold;
  border-width: 0;
  padding: 12px 15px;
  background-color: $tracker-table-border-color;
  border-right: 1px solid $tracker-table-border-color; // This is to resolve the white border on IE11
  border-left: 1px solid $tracker-table-border-color; // This is to resolve the white border on IE11
}

.client-tracker .table.tracker-quote-tbl th:first-of-type {
  border-radius: 5px 0 0 5px;
}

.client-tracker .table.tracker-quote-tbl th:last-of-type {
  border-radius: 0 5px 5px 0;
  padding-right: 30px;
}

.client-tracker .table.tracker-quote-tbl tr td {
  border-color: $tracker-table-border-color;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px 0;
  padding: 12px;
}

.client-tracker .table.tracker-quote-tbl tr.additional td {
  // We need to retain the values on top when the row height expanded
  // Check 9405 about additional services on Client Tracker
  vertical-align: top;
  background-color: #F3F8FF;
}

.client-tracker .table.tracker-quote-tbl tr.additional td,
.client-tracker .table.tracker-quote-tbl tr.additional td:first-of-type,
.client-tracker .table.tracker-quote-tbl tr.additional td:last-of-type {
  border-color: #79A7E2;
}

.client-tracker .table.tracker-quote-tbl tr td:first-of-type {
  border-left: 1px solid $tracker-table-border-color;
  border-radius: 5px 0 0 5px;
}

.client-tracker .table.tracker-quote-tbl tr td:last-of-type {
  border-right: 1px solid $tracker-table-border-color;
  border-radius: 0 5px 5px 0;
  padding-right: 30px;
}

.client-tracker .amount-container {
  background-color: #F6F6F6;
  display: flex;
  justify-content: space-between;
  border-radius: 0.25rem;
  padding: 0.25rem 30px 0.25rem 15px;
  margin: 0.25rem 0;
  align-items: center;
}

.client-tracker .amount-container .value {
  font-size: 15px;
}

.client-tracker .amount-container.total {
  background-image: linear-gradient(to right, #2A64B8, #113A75);
  color: white;
}

.client-tracker .amount-container.total .value {
  font-size: 20px;
}
// ---------------------------------------------------------------------------------------------------------------
// END: Client Tracker Table
// ---------------------------------------------------------------------------------------------------------------

//.btn-add {
//  height: 20px;
//}
