// Global theme for PRIME CHECKBOXES
// WE ARE NOT SUPPOSED to add custom styles on per page or per component on this APP.
// You shouldn't be changing this unless you know what you are doing.

@import "../variables";

$checkbox-color: #a6a6a6;

body .ui-chkbox-label {
  font-size: 1em;
}

body .ui-chkbox .ui-chkbox-box {
  border: 1px solid $checkbox-color;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active,
body .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-focus {
  background-color: $primary;
  border-color: $primary;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
  background-color: darken($primary, 10%);
  border-color: darken($primary, 10%);
}

body .ui-chkbox-label {
  text-transform: none;
}

body .ui-chkbox-label.ui-label-disabled {
  opacity: 0.5;
}

// This is a very specific override for service selections in case intake.
// Override selector was based on original selector from prime styles.
body p-checkbox.ng-dirty.ng-invalid .ui-chkbox.intake-service-list-checkbox .ui-chkbox-box {
  border: 1px solid $checkbox-color;
}

p-checkbox.ng-touched.ng-invalid .ui-chkbox-label,
p-checkbox.ng-dirty.ng-invalid .ui-chkbox-label{
  color: $danger;
  font-weight: bold;
}
