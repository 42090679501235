// Global theme for PRIME MULTISELECT DROPDOWNS
// WE ARE NOT SUPPOSED to add custom styles on per page or per component on this APP.
// You shouldn't really be touching this unless styling globally.

// Also - You must always be using <app-multi-dropdown>

@import "../variables.scss";

body .ui-multiselect.full-width {
  width: 100%;
}

// <app-multidropdown> scoped styles
// <app-multidropdown> contains visual enhancements that we need day-to-day
body .ui-multiselect.app-multiselect {
  width: 150px;
  height: $input-height;
  border-radius: $input-border-radius;
  border-color: $input-border-color;
}

body .ui-multiselect.app-multiselect.sm {
  height: 1.75rem;
}

body .ui-multiselect.app-multiselect.lg {
  height: $input-height-lg;
}

body .ui-multiselect.app-multiselect .ui-multiselect-label {
  padding: calc(#{$input-padding-y} + 0.2rem) $input-padding-x;
  color: $input-color;
  font-weight: $input-font-weight;
  height: $input-height;
  text-overflow: clip;
}

body .ui-multiselect.app-multiselect.sm .ui-multiselect-label {
  padding: $input-padding-y-sm $input-padding-x-sm;
  font-size: $input-font-size-sm;
  height: $input-height-sm;
}

body .ui-multiselect.app-multiselect.lg .ui-multiselect-label {
  padding: calc(#{$input-padding-y-lg} + 0.3rem) $input-padding-x-lg;
  font-size: $input-font-size-lg;
  height: $input-height-lg;
}

body .ui-multiselect.app-multiselect.full-width {
  width: 100%;
}

body .ui-multiselect.app-multiselect .ui-multiselect-label-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
