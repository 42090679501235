@import "../variables.scss";

body .ui-inputtext {
  color: $input-color;
  font-size: $input-font-size;
}

body .ui-inputtext.form-control {
  border: $input-border-width solid $input-border-color;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

body .ui-inputtext:enabled:hover:not(.ui-state-error) {
  border-color: $input-border-color;
}

body .ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-color: $input-focus-border-color;
  box-shadow: $input-box-shadow, $input-focus-box-shadow;
}
