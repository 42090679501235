// NOTE: P-rating does not support styleClass. We'll be using normal classes to customize this.
//.quality-issues {
//  display: block;
//}

.quality-issues .ui-rating {
  display: flex;
  width: 100%;
  justify-content: center;
}

.quality-issues .ui-rating > a {
  width: 60px;
  flex-shrink: 0;
  //color: gold;
}

