@import "../variables";

app-editor {
  border: 1px solid transparent;
}

app-editor.ng-invalid.ng-touched {
  border: 1px solid $danger;
  border-width: 2px;
}

// Default editor wrapper styling when disabled
.editor-wrapper.ui-editor-container .ui-editor-content.ql-disabled .ql-editor {
  background-color: $input-disabled-bg;
}

// Styling of clear editor
.clear-editor.ui-editor-container .ui-editor-toolbar {
  border-width: 0;
  background-color: #FFFFFF;
}

.clear-editor.ui-editor-container .ui-editor-content {
  border-width: 0;
}
