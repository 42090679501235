@import "../../../styles/variables";

.ui-table.university table {
  border-collapse: separate;
  border-spacing: 0;
}

.ui-table.university .ui-table-thead > tr > th {
  background-color: #9F9F9F;
  padding: 12px 10px 10px;
  border: none;
  color: #FFFFFF;
  border: 1px solid #9F9F9F;
}

.ui-table.university .ui-table-thead > tr > th:focus {
  outline: none;
}

.ui-table.university .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #9F9F9F;
  color: #FFFFFF;
}

.ui-table.university .ui-table-tbody > tr.row-spacer {
  border-width: 0;
  height: 6px;
}
.ui-table.university .ui-table-tbody > tr.row-spacer:first-of-type {
  border-width: 0;
  height: 15px;
}

.ui-table.university .ui-table-thead > tr > th:first-of-type {
  border-radius: 3px 0 0 3px;
}

.ui-table.university .ui-table-thead > tr > th:last-of-type {
  border-radius: 0 3px 3px 0;
}

.ui-table.university .ui-table-thead > tr.filter > th:first-of-type {
  border-radius: 3px 0 0 0;
}

.ui-table.university .ui-table-thead > tr.filter > th:last-of-type {
  border-radius: 0 3px 0 0;
}

.ui-table.university .ui-table-thead > tr.table-filters > th:first-of-type {
  border-radius: 0 0 0 3px;
}

.ui-table.university .ui-table-thead > tr.table-filters > th:last-of-type {
  border-radius: 0 0 3px 0;
}

.ui-table.university .ui-table-thead > tr.table-filters > th {
  padding: 4px;
}

.ui-table.university .ui-table-tbody > tr > td:first-of-type {
  border-width: 1px 0 1px 1px;
  border-radius: 3px 0 0 3px;
}

.ui-table.university .ui-table-tbody > tr > td:last-of-type {
  border-width: 1px 1px 1px 0;
  border-radius: 0 3px 3px 0;
}

.ui-table.university .ui-table-tbody > tr > td {
  border-width: 1px 0;
}

.ui-table.university .ui-table-tbody > tr.edit-university-container > td {
  border: 2px solid $primary;
  border-radius: 3px;
}

.ui-table.university p-sortIcon i {
  padding-top: 1px;
}

.ui-table.university .ui-table-tbody > tr > td {
  background-color: #FFFFFF;
}
