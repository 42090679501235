@import "../variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";

p-calendar.ng-touched.ng-invalid .ui-inputtext {
  border-color: $danger;
}

.advanced-datepicker-calendar.ui-calendar .ui-datepicker-inline {
  border: 0;
  overflow: hidden;
}

/* [START] Custom DatePicker component */
.custom-date-picker .ui-inputtext.form-control {
  @extend .form-control;
  padding-left: 37px;
}

// Readonly of p-calendar input must look like editable
.custom-date-picker.ui-calendar .ui-inputtext.form-control[readonly] {
  background-color: #FFFFFF;
  border-color: $input-border-color;
}

// Disabled custom date picker must look like readonly of input
.custom-date-picker.ui-calendar .ui-inputtext.form-control[disabled] {
  background-color: $input-disabled-bg;
  border-color: $input-disabled-bg;
}

.custom-date-picker .ui-inputtext.form-control.form-control-sm {
  @extend .form-control-sm;
}

.custom-date-picker .ui-inputtext.form-control.form-control-lg {
  @extend .form-control-lg;
}

.custom-date-picker .ui-inputtext.form-control:hover:not(.disabled),
.custom-date-picker .ui-inputtext.form-control:hover:not(.ui-state-error):not(.disabled) {
  border-color: $input-border-color;
}

.custom-date-picker.ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:hover:not(.ui-state-error) {
  border-right: 1px solid $input-border-color;
}

 // disabled state
.custom-date-picker .ui-inputtext.form-control.disabled {
  background-color: $input-disabled-bg;
  // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
  opacity: 1;
  border-color: #e4e4e4;
}

// focused state
.custom-date-picker .ui-inputtext.form-control:focus:not(.ui-state-error),
.custom-date-picker .ui-inputtext.form-control:hover:focus:not(.ui-state-error) {
  color: $input-focus-color;
  background-color: $input-focus-bg;
  border-color: $input-focus-border-color;
  box-shadow: $input-box-shadow, $input-focus-box-shadow;
  outline: 0;
}

// invalid state
p-calendar.ng-touched.ng-invalid .custom-date-picker .ui-inputtext.form-control,
p-calendar.ng-touched.ng-invalid .custom-date-picker .ui-inputtext.form-control:hover:not(.ui-state-error),
app-date-picker.ng-touched.ng-invalid .custom-date-picker .ui-inputtext.form-control,
app-date-picker.ng-touched.ng-invalid .custom-date-picker .ui-inputtext.form-control:hover:not(.ui-state-error),
app-date-picker.ng-touched.ng-invalid .custom-date-picker .ui-calendar-button,
app-date-only-picker.ng-touched.ng-invalid .custom-date-picker .ui-inputtext.form-control,
app-date-only-picker.ng-touched.ng-invalid .custom-date-picker .ui-inputtext.form-control:hover:not(.ui-state-error),
app-date-only-picker.ng-touched.ng-invalid .custom-date-picker .ui-calendar-button {
  border: 2px solid $form-feedback-invalid-color;
}

.ng-touched.ng-invalid .custom-date-picker .ui-inputtext.form-control:focus:not(.ui-state-error) {
  box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, .25);
}

// icon styling
.custom-date-picker .ui-calendar-button {
  background-color: #FFFFFF;
  min-width: auto;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  position: absolute;
  border: 1px solid $input-border-color;
  border-right-width: 0 !important;
  border-radius: 0;
}

.custom-date-picker .ui-calendar-button span.ui-button-icon-left {
  color: $primary;
}

/* [END] Custom DatePicker component */
