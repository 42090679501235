// PRIME GLOBAL SHARED STYLES.
// You're not supposed to be modifying things in here unless the main goal to to style it globally

@import "../variables";

// Modifies the font family of all dropdown items.
body .ui-widget {
  font-family: $font-family-sans-serif;
}

// Prime overwrites the a in it's global style. We're making sure it is still the primary
body a {
  color: $primary;
}

body a:hover,
body a:active {
  color: darken($primary, 10%);
}

// Modifies the color of all the prime buttons
body .ui-button {
  background-color: $primary;
  border-color: $primary;
}
