.draggable-item {
  position: relative;
  background-color: inherit;
}

.draggable-item.cdk-drag-placeholder {
  opacity: 0.5;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .draggable-item {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.draggable-item.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.draggable-item-handle {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ccc;
  cursor: move;
  width: 24px;
  height: 24px;
}
