@import "../variables.scss";

body .ui-spinner .ui-spinner-button {
  background-color: transparent;
  color: $primary;
  border-color: $primary;
}

body .ui-spinner .ui-spinner-button:hover {
  color: white;
  background-color: $primary;
}

body .ui-spinner .ui-spinner-button.ui-spinner-up {
  border-bottom: 0;
}

.advanced-datepicker-spinner.form-control.ui-inputtext {
  width: 70px;
}
