@import "../../variables.scss";

.ui-table.ui-widget.default-table {
  font-size: 12px;
}

// Table Header Styling
.ui-table.default-table .ui-table-thead > tr:last-of-type > th {
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 1px;
  background-color: #9F9F9F;
  padding: 0.3em 0.857em;
}

.ui-table.default-table .ui-table-thead > tr:first-of-type > th:first-of-type {
  border-top-left-radius: 3px;
}

.ui-table.default-table .ui-table-thead > tr:first-of-type > th:last-of-type {
  border-top-right-radius: 3px;
}

.ui-table.default-table .ui-table-thead > tr:last-of-type > th:first-of-type {
  border-bottom-left-radius: 3px;
}

.ui-table.default-table .ui-table-thead > tr:last-of-type > th:last-of-type {
  border-bottom-right-radius: 3px;
}

.ui-table.default-table .ui-table-thead > tr:first-of-type > th {
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-top: 1px solid #BABABA;
  background-color: #9F9F9F;
  padding: 10px 0.857em;
  color: #FFFFFF;
}

.ui-table.default-table .ui-sortable-column.ui-state-highlight,
.ui-table.default-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #9F9F9F;
  color: #FFFFFF;
}

.ui-table.default-table .ui-sortable-column:focus {
  outline: 0;
}

// Table Body Styling

.ui-table.default-table .ui-table-tbody > tr > td {
  padding: 0.150em 0.857em;
  background-color: #FFFFFF;
  color: #333333;
  border-width: 1px 0 1px 0;
  font-size: 13px;
}

.ui-table.default-table .ui-table-tbody > tr.row-spacer {
  border-width: 0;
}

.ui-table.default-table .ui-table-tbody > tr.row-data > td:first-of-type {
  border-left-width: 1px;
}

.ui-table.default-table .ui-table-tbody > tr.row-data > td:last-of-type {
  border-right-width: 1px;
}

.ui-table.default-table p-sortIcon {
  vertical-align: baseline;
}

// Hiding of p-table's native paginator
.ui-table.hide-table-paginator p-paginator {
  display: none;
}
