
body .ui-fileupload.tiny-upload .ui-fileupload-buttonbar {
  display: none;
}

body .ui-fileupload.tiny-upload .ui-fileupload-content {
  border: 0;
  padding: 0;
  background-color: #FBFBFB
}

body .ui-fileupload.tiny-upload .ui-fileupload-content .tiny-upload-content {
  border: 1px dashed #c8c8c8;
}

body .ui-fileupload.tiny-upload .ui-fileupload-content .tiny-upload-content.disabled-ss-upload {
  background-color: #C8C8C8;
  opacity: 54%;
  pointer-events: none;
}

//Case files tab drag and drop style
body  .ui-fileupload.grey-upload .ui-fileupload-buttonbar {
  display: none;
}

body  .ui-fileupload.grey-upload .ui-fileupload-content {
  background-color: #4E4E4E;
  color: white;
}

body  .ui-fileupload.grey-upload .ui-fileupload-buttonbar {
  background-color: #4E4E4E;
  color: white;
}
