// Global theme for PRIME DROPDOWNS
// WE ARE NOT SUPPOSED to add custom styles on per page or per component on this APP.
// You shouldn't really be touching this unless styling globally.

// Also - You must always be using <app-dropdown>

@import "../variables";

body .ui-dropdown.full-width {
  width: 100%;
  min-width: 0;   // DO NOT remove this. You don't know what you are doing, I am sure.
}

body .ui-dropdown {
  border-color: #ced4da;
}

// <app-dropdown> scoped styles
// <app-dropdown> contains visual enhancements that we need day-to-day
body .ui-dropdown.app-dropdown {
  height: $input-height;
  width: 170px;
  border-radius: $input-border-radius;
  border-color: $input-border-color;
}

body .ui-dropdown.app-dropdown.full-width {
  width: 100%;
}

body .ui-dropdown.app-dropdown label.ui-dropdown-label {
  text-overflow: ellipsis;
  padding: calc(#{$input-padding-y} + 0.25rem) $input-padding-x;
  background: none;
  height: $input-height;
}

body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
  border-color: $primary;
  box-shadow: 0 0 0 0.2rem rgba(97, 145, 43, 0.25);
}

// LARGE SIZING
body .ui-dropdown.app-dropdown.lg {
  height: $input-height-lg; // Match Bootstrap's large input size
}

body .ui-dropdown.app-dropdown.lg label.ui-dropdown-label {
  font-size: $input-font-size-lg;
  height: $input-height-lg;
  padding: calc(#{$input-padding-y-lg} + 0.25rem) $input-padding-x-lg;
}

// SMALL SIZING
body .ui-dropdown.app-dropdown.sm {
  //height: $input-height-sm; // Doesn't seem to match properly.
  height: 1.75rem;
}

body .ui-dropdown.app-dropdown.sm label.ui-dropdown-label {
  margin-top: 0;
  font-size: $input-font-size-sm;
  padding-top: $input-padding-y-sm;
}

// Angular Form Validation States
body app-dropdown.ng-touched.ng-invalid .ui-dropdown.app-dropdown,
body app-dropdown.ng-dirty.ng-invalid .ui-dropdown.app-dropdown {
  border-color: $danger;
  border-width: 2px;
}

// Modifies all the color of the dropdown panel highlights.
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {
  background-color: $primary;
}

$copied: #7ED321;
.dropdown-with-copy .app-dropdown.ui-dropdown {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.dropdown-with-copy .copy-input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.dropdown-with-copy .btn-copy {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  @extend .btn-outline-secondary;
}

.dropdown-with-copy.copied {
  box-shadow: $input-box-shadow, 0 0 0 $input-btn-focus-width rgba($copied, .25);
}

.dropdown-with-copy.copied .app-dropdown.ui-dropdown {
  border-color: $copied;
}

.dropdown-with-copy.copied .btn-copy,
.dropdown-with-copy.copied .btn-copy:hover {
  @extend .btn-primary;
  background-color: $copied;
  border-color: $copied;
  box-shadow: none !important;
}

// No min-width
body .ui-dropdown.min-width-none {
  min-width: 0;
}

// This will fix the alignment of the label when dropdown is inside of a table
body table .ui-dropdown.app-dropdown.ui-helper-clearfix::before {
  display: block;
}
