@import "../variables";

.alert-danger {
  font-weight: bold;
}

// Modified version of the primary to match current
.alert-primary {
  background-color: white;
  border-color: $primary;
  border-left-width: 8px;
}

.alert-info {
  background-color: #D4E0F0;
  border-color: #D4E0F0;
  color: #1231AE;
}
