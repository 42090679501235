@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.panel.park-ad-team {
  border-width: 0;
}

.panel.park-ad-team .panel-heading {
  padding: 6px 20px;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
}

// For panel used inside the PM workspace (ParkAds internal)
.panel.workspace-section-panel {
  border-width: 0;
}

.panel.card.workspace-section-panel {
  background-color: transparent;
}

.panel.workspace-section-panel .panel-heading {
  padding: 18px 18px;
  border: none;
  background-color: #E0E0E0;
  border-radius: 0;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.panel.workspace-section-panel .panel-body {
  padding: 15px 0;
}

.basic-accordion {
  border: none;
}

.basic-accordion .accordion-toggle {
  cursor: pointer;
}

.basic-accordion .panel-heading {
  background: none;
  border-bottom: 0;
  padding: 0;
  border: none;
}

.basic-accordion .panel-body {
  padding: 0;
}

.detached-accordion {
  border: 0
}

.detached-accordion .card-header {
  background-color: #6F6F6F;
  color: #ffffff;
  cursor: pointer;
  border-radius: $border-radius;
  padding: 0;
}

.detached-accordion .card-body {
  padding: 0;
}
