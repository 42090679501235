@import "variables";

/* advanced-datepicker global overrides - START */

app-advanced-datepicker.ng-invalid.ng-touched .advanced-datepicker .form-control,
app-advanced-datepicker.ng-invalid.ng-touched .advanced-datepicker .form-control.ui-inputtext:enabled:hover:not(.ui-state-error) {
  border-color: $danger;
  border-width: 2px;
}

app-advanced-datepicker.ng-invalid.ng-touched .advanced-datepicker .form-control.ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-color: $danger;
  box-shadow: 0 0 0 $input-focus-width rgba($danger, .25);
}

/* advanced-datepicker global overrides - END */

/* template-editor global overrides - START */

app-template-editor.ng-invalid.ng-touched {
  border-color: $danger;
  border-width: 2px;
}

/* template-editor global overrides - END */
